import React, { SVGProps } from "react";

export const GlobeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM6.81721 9.16667H4.90019C5.19468 7.35055 6.43589 5.85189 8.10103 5.19348C7.40119 6.40858 6.96166 7.76194 6.81721 9.16667ZM8.49444 9.16667C8.66615 7.77819 9.18429 6.45535 10 5.32029C10.8157 6.45535 11.3339 7.77819 11.5056 9.16667H8.49444ZM10 14.6797C10.8157 13.5447 11.3339 12.2218 11.5056 10.8333H8.49444C8.66615 12.2218 9.18429 13.5447 10 14.6797ZM4.90019 10.8333H6.81721C6.96166 12.2381 7.40119 13.5914 8.10103 14.8065C6.43589 14.1481 5.19468 12.6494 4.90019 10.8333ZM13.1828 10.8333C13.0383 12.2381 12.5988 13.5914 11.899 14.8065C13.5641 14.1481 14.8053 12.6494 15.0998 10.8333H13.1828ZM13.1828 9.16667H15.0998C14.8053 7.35055 13.5641 5.85189 11.899 5.19348C12.5988 6.40858 13.0383 7.76194 13.1828 9.16667ZM10 3.16667C6.22605 3.16667 3.16667 6.22605 3.16667 10C3.16667 13.7739 6.22605 16.8333 10 16.8333C13.7739 16.8333 16.8333 13.7739 16.8333 10C16.8333 6.22605 13.7739 3.16667 10 3.16667Z" fill="currentColor"/>
      </svg>
  )
}

export const TelegramIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10.3585 7.38254C9.38581 7.7871 7.44188 8.62443 4.52668 9.89454C4.0533 10.0828 3.80532 10.2669 3.78274 10.447C3.74459 10.7513 4.1257 10.8712 4.64466 11.0344C4.71525 11.0566 4.7884 11.0796 4.86338 11.1039C5.37397 11.2699 6.06079 11.4641 6.41784 11.4718C6.74172 11.4788 7.10321 11.3453 7.5023 11.0712C10.2261 9.2326 11.6321 8.30328 11.7204 8.28324C11.7826 8.26911 11.8689 8.25134 11.9274 8.30331C11.9859 8.35528 11.9801 8.4537 11.9739 8.48011C11.9362 8.64105 10.4402 10.0318 9.66604 10.7516C9.4247 10.976 9.2535 11.1351 9.2185 11.1715C9.14011 11.2529 9.06021 11.3299 8.98342 11.4039C8.50907 11.8612 8.15336 12.2041 9.00311 12.7641C9.41147 13.0332 9.73824 13.2557 10.0642 13.4777C10.4202 13.7202 10.7753 13.962 11.2348 14.2632C11.3518 14.3399 11.4636 14.4196 11.5725 14.4972C11.9868 14.7926 12.3591 15.0579 12.8189 15.0156C13.0861 14.991 13.3622 14.7398 13.5023 13.9904C13.8336 12.2194 14.4848 8.38215 14.6353 6.80091C14.6485 6.66238 14.6319 6.48508 14.6186 6.40725C14.6052 6.32942 14.5774 6.21852 14.4762 6.13644C14.3564 6.03922 14.1715 6.01871 14.0887 6.02017C13.7126 6.0268 13.1356 6.22745 10.3585 7.38254Z" fill="currentColor"/>
      </svg>
  )
}

export const XIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.5459 5L13.3333 10.9287L19 19H14.8308L11.0135 13.5637L6.2348 19H5L10.4661 12.7836L5 5H9.1692L12.7831 10.1468L17.3111 5H18.5459ZM8.57747 5.91111H6.68047L15.4127 18.1337H17.3102L8.57747 5.91111Z" fill="currentcolor"/>
    </svg>
  )
}