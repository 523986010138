import { StackedLineData } from 'components/Charts/StackedLineChart'
import { StackedBarsData } from 'components/Charts/StackedVolumeChart/renderer'

export const HARDCODED_VOLUME_DATA = [
  { time: 1702500046, values: { v2: 1000000, v3: 3000000 } },
  { time: 1702500146, values: { v2: 1400000, v3: 4000000 } },
  { time: 1702500246, values: { v2: 1300000, v3: 3500000 } },
  { time: 1702500346, values: { v2: 2000000, v3: 7000000 } },
  { time: 1702500446, values: { v2: 1000000, v3: 8000000 } },
  { time: 1702500546, values: { v2: 4000000, v3: 15000000 } },
  { time: 1702500646, values: { v2: 9250000, v3: 16000000 } },
  { time: 1702500746, values: { v2: 6000000, v3: 9000000 } },
  { time: 1702500846, values: { v2: 5000000, v3: 8500000 } },
  { time: 1702500946, values: { v2: 6000000, v3: 7000000 } },
  { time: 1702501046, values: { v2: 10000000, v3: 5000000 } },
  { time: 1702501146, values: { v2: 11000000, v3: 6000000 } },
  { time: 1702501246, values: { v2: 5000000, v3: 8000000 } },
  { time: 1702501346, values: { v2: 11000000, v3: 6500000 } },
  { time: 1702501446, values: { v2: 2000000, v3: 8000000 } },
  { time: 1702501546, values: { v2: 1900000, v3: 7000000 } },
  { time: 1702502046, values: { v2: 1000000, v3: 3000000 } },
  { time: 1702502146, values: { v2: 1400000, v3: 4000000 } },
  { time: 1702502246, values: { v2: 1300000, v3: 3500000 } },
  { time: 1702502346, values: { v2: 2000000, v3: 7000000 } },
  { time: 1702502446, values: { v2: 1000000, v3: 8000000 } },
  { time: 1702502546, values: { v2: 4000000, v3: 7000000 } },
  { time: 1702502646, values: { v2: 9250000, v3: 6000000 } },
  { time: 1702502746, values: { v2: 6000000, v3: 9000000 } },
  { time: 1702502846, values: { v2: 5000000, v3: 8500000 } },
  { time: 1702502946, values: { v2: 6000000, v3: 7000000 } },
  { time: 1702503046, values: { v2: 10000000, v3: 5000000 } },
  { time: 1702503146, values: { v2: 11000000, v3: 6000000 } },
  { time: 1702503246, values: { v2: 5000000, v3: 8000000 } },
  { time: 1702503346, values: { v2: 11000000, v3: 6500000 } },
  { time: 1702503446, values: { v2: 2000000, v3: 8000000 } },
  { time: 1702503546, values: { v2: 1900000, v3: 7000000 } },
  { time: 1702504046, values: { v2: 1000000, v3: 3000000 } },
  { time: 1702504146, values: { v2: 1400000, v3: 4000000 } },
  { time: 1702504246, values: { v2: 1300000, v3: 3500000 } },
  { time: 1702504346, values: { v2: 2000000, v3: 7000000 } },
  { time: 1702504446, values: { v2: 1000000, v3: 8000000 } },
  { time: 1702504546, values: { v2: 4000000, v3: 12000000 } },
  { time: 1702504646, values: { v2: 9250000, v3: 14000000 } },
  { time: 1702504746, values: { v2: 6000000, v3: 9000000 } },
  { time: 1702504846, values: { v2: 5000000, v3: 8500000 } },
  { time: 1702504946, values: { v2: 6000000, v3: 7000000 } },
  { time: 1702505046, values: { v2: 10000000, v3: 5000000 } },
  { time: 1702505146, values: { v2: 9000000, v3: 6000000 } },
  { time: 1702505246, values: { v2: 5000000, v3: 8000000 } },
  { time: 1702505346, values: { v2: 4000000, v3: 6500000 } },
  { time: 1702505446, values: { v2: 2000000, v3: 8000000 } },
  { time: 1702505546, values: { v2: 1900000, v3: 7000000 } },
] as StackedBarsData[]

export const HARDCODED_TVL_DATA = [
  { time: 1702500046, values: [1000000, 3000000] },
  { time: 1702500146, values: [1400000, 4000000] },
  { time: 1702500246, values: [1300000, 3500000] },
  { time: 1702500346, values: [2000000, 7000000] },
  { time: 1702500446, values: [1000000, 8000000] },
  { time: 1702500546, values: [4000000, 15000000] },
  { time: 1702500646, values: [9250000, 16000000] },
  { time: 1702500746, values: [6000000, 9000000] },
  { time: 1702500846, values: [5000000, 8500000] },
  { time: 1702500946, values: [6000000, 7000000] },
  { time: 1702501046, values: [10000000, 5000000] },
  { time: 1702501146, values: [11000000, 6000000] },
  { time: 1702501246, values: [5000000, 8000000] },
  { time: 1702501346, values: [11000000, 6500000] },
  { time: 1702501446, values: [2000000, 8000000] },
  { time: 1702501546, values: [1900000, 7000000] },
] as StackedLineData[]
